<template>
  <div>
    <ek-table
      :items="studentList"
      :columns="StudentsCols"
      @details="goToDetails"
      @delete-selected="deleteStudents"
      :row-style-class="rowStyleClassFn"
      deleteBtn
    >
      <template slot="items.dateCreated" slot-scope="{ value }">
        {{ value ? new Date(value).toLocaleDateString() : "" }}
      </template>

      <template slot="items.endDate" slot-scope="{ value }">
        {{ value ? new Date(value).toLocaleDateString() : "_" }}
      </template>

      <template slot="items.isSub" slot-scope="{ value }">
        <StatusBadge :value="value" :options="statusStudentList" />
      </template>
    </ek-table>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import StatusBadge from "@global/components/StatusBadge.vue";

export default {
  components: {
    StatusBadge,
  },
  computed: {
    ...mapState({
      students: ({ students }) => students.students,
      StudentsCols: ({ students }) => students.StudentsCols,
      statusStudentList: (state) => state.students.statusStudent,
    }),
    ...mapGetters(["studentList"]),
  },
  data: () => ({}),
  methods: {
    ...mapActions(["getStudentsList", "deleteStudents"]),
    goToDetails({ row }) {
      let rowDotName = row.name;
      this.$router.push({ path: `students/${row.id}`, query: { name: rowDotName } });
    },
    rowStyleClassFn(row) {
      return row.isBlocked ? "bg-danger" : "";
    },
  },
  beforeDestroy() {
    this.$store.commit("Set_Student_Dto");
  },
  created() {
    this.getStudentsList();
  },
};
</script>
